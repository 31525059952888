<!-- 背景图 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="atas">
                <div class="sta">
                    <p>
                        <span>修改背景图1</span>
                        <span>
                            <!-- <el-upload class="avatar-uploader" action="" :http-request="upload_24"
                                :show-file-list="false" :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload" list-type="picture-card">
                                <img :key="imageUrl24" v-if="imageUrl24 != '' && imageUrl24 != undefined"
                                    :src="imageUrl24" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                            </el-upload> -->
                            <img :key="imageUrl24" v-if="imageUrl24 != '' && imageUrl24 != undefined" :src="imageUrl24"
                                class="avatar">
                            <el-button type="primary" @click="add1">确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图2</span>
                        <span>
                            <img :key="imageUrl25" v-if="imageUrl25 != '' && imageUrl25 != undefined" :src="imageUrl25"
                            class="avatar">
                            <el-button type="primary" @click="add2">确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图3</span>
                        <span>
                            <img :key="imageUrl26" v-if="imageUrl26 != '' && imageUrl26 != undefined" :src="imageUrl26"
                            class="avatar">
                            <el-button type="primary" @click="add3">确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图4</span>
                        <span>
                            <img :key="imageUrl27" v-if="imageUrl27 != '' && imageUrl27 != undefined" :src="imageUrl27"
                            class="avatar">
                            <el-button type="primary" @click="add4">确认</el-button>
                        </span>
                    </p>
                </div>
                <el-dialog v-model="dialogVisible" title="静态轮播图" width="500" draggable :before-close="handleClose">
                    <el-form :model="form" label-width="10vw">
                        <el-form-item label="图片">
                            <!-- <el-input v-model="name" style="width: 20vw" placeholder="名称" clearable /> -->
                            <!-- <el-upload v-model:file-list="fileList"
                                action="https://199shq.com/api/announcement/upload" name="file" limit="1"
                                list-type="picture-card" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemove" disabled>
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload> -->

                            <el-upload v-model:file-list="fileList"
                                action="https://199shq.com/api/announcement/upload" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success="handleSuccess">
                                <el-icon>
                                    +
                                </el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisiblet">
                                <img w-full :src="dialogImageUrlt" alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                        <el-form-item label="类型">
                            <el-radio-group v-model="typeNumber">
                                <el-radio :label="0" value="0">图片</el-radio>
                                <el-radio :label="1" value="1">跳转</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="选择公告" v-if="typeNumber == 1">
                            <el-input style="width: 20vw;" v-model="productNames" readonly maxlength="100" placeholder="请选择关联的公告"
                                @click="openProductDialog" />
                        </el-form-item>
                    </el-form>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="toggleModalss">取消</el-button>
                            <el-button type="primary" @click="onSubmitss">
                                确认
                            </el-button>
                        </div>
                    </template>
                </el-dialog>
            </div>
        </el-container>
    </el-container>
    <Editstat v-model:modelValue="isReasonDialogVisible" @confirm="setRelativeProduct" />
</template>

<script setup>
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw ,onMounted} from 'vue';
import { Plus } from 'element-plus';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit } from 'vxe-table';
import { useRouter, useRoute } from 'vue-router';
import Editstat from './Editstat.vue'
const imgurl24 = ref('')
const imageUrl24 = ref('')
const imgurl25 = ref('')
const imageUrl25 = ref('')
const imgurl26 = ref('')
const imageUrl26 = ref('')
const imgurl27 = ref('')
const imageUrl27 = ref('')
const data = ref(null);
const list = ref([]);
const dialogImageUrlt = ref('')
const dialogVisiblet = ref(false)
const isReasonDialogVisible = ref(false);
const typeNumber = ref('')
const productIds = ref('')
const toggleModalss = () => {
    dialogVisible.value = !dialogVisible.value
    priority.value = 0
    isActive.value = ''
    ids.value = ''
}
onMounted(()=>{
    all()
})
const url = ref('')
const handleSuccess = (response, file, files) => {

// 此处的response就是服务器响应的对象
url.value = response.data;
console.log(response)
}
const productNames = ref('')
const setRelativeProduct = (value) => {
    console.log(value)
    productIds.value = value.id;
    productNames.value = value.title;
    console.log(productIds.value, productNames.value)
}
const openProductDialog = () => {
    isReasonDialogVisible.value = true
}
const handleRemove = (uploadFile, uploadFiles) => {
    // 点击删除的时候将对象的值设置为null
    //   c.value.mediaUrl = null;
    console.log(uploadFile.url)
    const regexs = /(https:\/\/199shq.com\/api\/common\/download\?name=([^,]+))/;
    let matches = uploadFile.url.match(regexs);
    console.log(matches)
    // 发出删除文件的请求
    let url = ''//得到当前的文件路径
    console.log(matches);
    if (matches === null) {
        url = uploadFile.url
    } else {
        if (matches[2] === undefined) {
            url = matches[4]
        } else {
            url = matches[2]
        }
    }
    instance.delete("/api/upload/remove?url=" + url)
        .then((response) => {
            if (response.data.state == 20000) {
                ElMessage.success("删除文件完成！")
            }
        })
}
const fileList = ref([])
const handlePictureCardPreview = (uploadFile) => {
    console.log(uploadFile.url);
    dialogImageUrlt.value = uploadFile.url
    dialogVisiblet.value = true
}
const dialogVisible = ref(false)
const isActive = ref('')
const verify = ref(false)
const priority = ref('')
const ids = ref('')
const name = ref('')
const handleClose = () => {
    toggleModalss()
}
const all = ()=>{
instance.get(`/api/statics`)
    .then(response => {
        console.log(response.data.data);
        data.value = response.data.data;
        list.value = data.value;
        console.log(list.value[0].background);
        instance.get(`/api/common/download?name=${list.value[0].background}`)
            .then(response => {
                imageUrl24.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
        instance.get(`/api/common/download?name=${list.value[1].background}`)
            .then(response => {
                imageUrl25.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
        instance.get(`/api/common/download?name=${list.value[2].background}`)
            .then(response => {
                imageUrl26.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
        instance.get(`/api/common/download?name=${list.value[3].background}`)
            .then(response => {
                imageUrl27.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
    })
    .catch(error => {
        //token失效跳转登录页面
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('/')
    });
}

const onSubmitss = () => {
    console.log(url.value)
    console.log(typeNumber.value)
    if (ids.value === '' || fileList.value.length === 0 || typeNumber.value === '') {
        ElMessage({
            showClose: true,
            message: '绑定失败，请填写完全部信息',
            type: 'error',
        })
        return;
    }
    if (typeNumber.value === 2 && productIds.value === '') {
        ElMessage({
            showClose: true,
            message: '绑定失败，请绑定公告',
            type: 'error',
        })
        return;
    }
    if (typeNumber.value == 0) {
        console.log('进入')
        const data = {
            announcementId: '',
            background: url.value,
            id: ids.value,
            typeNumber: typeNumber.value

        }
        instance.put(`/api/statics`, data)
            .then(res => {
                ElMessage({
                    message: '绑定成功',
                    type: 'success',
                })
                all()
                toggleModalss()
            }).catch(err => {
                console.log(err)
            })
    } else {
        const data = {
            announcementId: productIds.value,
            background: url.value,
            id: ids.value,
            typeNumber: typeNumber.value

        }
        instance.put(`/api/statics`, data)
            .then(res => {
                ElMessage({
                    message: '绑定成功',
                    type: 'success',
                })
                all()
                toggleModalss()
            }).catch(err => {
                console.log(err)
            })
    }


}
const add1 = () => {
    dialogVisible.value = true
    ids.value = 1001
    instance.get(`/api/statics/${ids.value}`)
        .then(res => {
            console.log(res.data.data)
            url.value = res.data.data.background
            fileList.value = [{
                name: 'background',
                url: `https://199shq.com/api/common/download?name=${res.data.data.background}`
            }]
            typeNumber.value = res.data.data.typeNumber
            if (res.data.data.announcementId !== undefined) {
                productIds.value = res.data.data.announcementId
            }
        })
};

const add2 = () => {
    dialogVisible.value = true
    ids.value = 1002
    instance.get(`/api/statics/${ids.value}`)
        .then(res => {
            console.log(res.data.data)
            url.value = res.data.data.background
            fileList.value = [{
                name: 'background',
                url: `https://199shq.com/api/common/download?name=${res.data.data.background}`
            }]
            typeNumber.value = res.data.data.typeNumber
            if (res.data.data.announcementId !== undefined) {
                productIds.value = res.data.data.announcementId
            }
        })
};

const add3 = () => {
    dialogVisible.value = true
    ids.value = 1003
    instance.get(`/api/statics/${ids.value}`)
        .then(res => {
            console.log(res.data.data)
            url.value = res.data.data.background
            fileList.value = [{
                name: 'background',
                url: `https://199shq.com/api/common/download?name=${res.data.data.background}`
            }]
            typeNumber.value = res.data.data.typeNumber
            if (res.data.data.announcementId !== undefined) {
                productIds.value = res.data.data.announcementId
            }
        })
};
const add4 = () => {
    dialogVisible.value = true
    ids.value = 1004
    instance.get(`/api/statics/${ids.value}`)
        .then(res => {
            console.log(res.data.data)
            url.value = res.data.data.background
            fileList.value = [{
                name: 'background',
                url: `https://199shq.com/api/common/download?name=${res.data.data.background}`
            }]
            typeNumber.value = res.data.data.typeNumber
            if (res.data.data.announcementId !== undefined) {
                productIds.value = res.data.data.announcementId
            }
        })
};
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}


.adds {
    position: fixed;
    top: 0 !important;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/register.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.add {
    top: 20vh !important;
    position: fixed;
    width: 20vw;
    height: 70vh;
    left: 40vw;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.add p {
    display: flex;
    margin-top: 0vh !important;
}

.add input,
.add button {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 5vh;
}

.sta {
    overflow: auto;
    /* 设置页面内容溢出时显示滚动条 */
    height: 100%;
    width: 100%;
    position: relative;

    /* display: flex; */
}

.atas {
    width: 100vw;
    height: 90vh;
    display: flex;
    margin-top: 10vh;
}
</style>